import React from "react"
import { Link } from "gatsby"

import Phrase from "../Phrase"

import css from "./BrowseList.module.scss"
import utilities from "../../styles/utilities.module.scss"

export default ({ items, sortAlpha, sortFunc, placeholderPrefix }) => {
  const [filter, setFilter] = React.useState("")

  if (!items.length) {
    return []
  }

  const formId = React.useRef(
    `BrowseList--${Math.round(Math.random() * 999999)}`
  ).current

  if (sortAlpha) {
    items.sort((a, b) => {
      if (a.fields.name.toLowerCase() < b.fields.name.toLowerCase()) {
        return -1
      }
      if (a.fields.name.toLowerCase() > b.fields.name.toLowerCase()) {
        return 1
      }
      return 0
    })
  }
  if (sortFunc && typeof sortFunc === 'function') {
    items.sort(sortFunc);
  }

  const filteredItems = filter.length
    ? items.filter(
        i => i.fields.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
      )
    : items

  return (
    <>
      {placeholderPrefix && placeholderPrefix.length && (
        <label
          htmlFor={`${formId}__query`}
          className={utilities.screenreaderOnly}
        >
          {placeholderPrefix}
        </label>
      )}
      {items.length > 1 && (
        <input
          type="search"
          id={`${formId}__query`}
          className={css.browseFilter}
          placeholder={`${
            placeholderPrefix && placeholderPrefix.length
              ? placeholderPrefix + " "
              : ""
          }eg. ${items[0].fields.name}`}
          onChange={e => setFilter(e.target.value)}
        />
      )}
      <ul className={[
        css.browseList,
        items.length <= 1 ? css.browseListUnbound : '',
      ].join(' ')}>
        {filteredItems.map((i, key) => (
          <li key={key}>
            <Link to={`/${i.fields.slug}`}>
              <Phrase text={i.fields.name} />
            </Link>
          </li>
        ))}
      </ul>
    </>
  )
}

import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import BrowseList from "../components/BrowseList"

const Electorate = ({ data }) => {
  const elecFields = data.electorateCsv.fields;
  const elecName = elecFields.description;
  const elecSlug = elecFields.slug

  const parliamentarians = data.electorateCsv.parliamentarian || [];

  return (
    <Layout>
      <HeadMeta pageTitle={elecName} />
      <PageHeader>{elecName}</PageHeader>
      <Breadcrumb
        route={[
          { name: "Parliamentary Papers", href: "/" },
          { name: "NSW Electorates", href: "/electorates/" },
          { name: elecName, href: `/${elecSlug}` },
        ]}
      />
      <main>
        <h2>View all parliamentarians for this electorate</h2>
        <BrowseList items={parliamentarians} />
      </main>
    </Layout>
  )
}

export default Electorate;

export const query = graphql`
  query ElectoratePageQuery($electorateIds: [String]!) {
    electorateCsv(fields: {id: {in: $electorateIds}}) {
      fields {
        description
        slug
        parliamentarianId
      }
      parliamentarian {
        fields {
          name
          slug
        }
      }
    }
  }
`
